import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { InvisibleSmartCaptcha, SmartCaptcha } from '@yandex/smart-captcha';

import FormItem from '../../UI/fields/FormItem'
import { login, setUserDataItem } from '../../../store/userDataSlice'
import urls from '../../../urls';
import CabinetRequest from '../../../api/cabinet/Cabinet';
import error from '../../../functions/error';
import { API_CABINET_MODEL } from '../../../api/cabinet/const';
import Timer from '../../Timer/Timer';

const LoginCodeForm = ({ contragentActions }) => {
  const cabinet = new CabinetRequest()
  const [resendCode, setResendCode] = useState(false)
  const [token, setToken] = useState(''); //capcha

  const userData = useSelector(state => state.user_data.userData)
  const dispatch = useDispatch()
  const addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
  const userLogin = (name, value) => dispatch(login([name, value]))
  const [formError, setFormError] = useState(false)
  const [btnActive, setBtnActive] = useState(false)
  const [fields, setFields] = useState([
    {
      id: 'username',
      name: 'username',
      type: 'hidden',
    },
    {
      id: 'password',
      name: 'password',
      type: 'text',
      label: 'Код из СМС'
    },
  ])
  const [data, setData] = useState({
    username: userData.user_login,
  })
  const navigate = useNavigate();


  const setValue = (name, value) => {
    if (value) {
      data[name] = value
      //console.log('setValue', value)
    } else {
      delete data[name]
    }
    setData({ ...data })
    if (data.password) {
      setBtnActive(true)
    } else {
      setBtnActive(false)
    }
  }

  const setError = (name, value) => {
    const index = fields.findIndex(field => field.name === name)
    fields[index].error = value
    setFields(fields)
  }

  const send_code = () => {
    setResendCode(false)
    cabinet.sendCode({ phone: userData.user_login, captcha_token: token })
      .then(data => {
        //console.log('response', data)
        if (data.success) {
          //navigate('/code_accept')
        } else {
          setFormError(error(data))
        }
      })
  }

  const send_login = () => {
    setBtnActive(false)
    cabinet.login(data)
      .then(data => {

        if (data.success) {
          userLogin('token', data.data.access)
          userLogin('refresh', data.data.refresh)


          if (data.data.active && data.data.contragent) { // есть кабинет и контранент в коллективе

            addUserData('contragent', data.data.contragent[0].id)
            if (contragentActions) {
              contragentActions.pass(data.data.contragent[0].id) // создается задача
            } else {
              navigate(urls.cabinet_page.path) // редирект в кабинет
            }

          } else {

            if (data.data.contragent) {// есть только контранент в коллективе

              addUserData('contragent', data.data.contragent[0].id)
              if (contragentActions) {
                contragentActions.pass(data.data.contragent[0].id) // создается задача
              } else {
                navigate(urls.register.path) // редирект в регистрацию , { state: { contragent: JSON.stringify(data.data.contragent) } } 
              }

            } else { // нет контранена и/или кабинета

              if (contragentActions) {
                contragentActions.create()
              } else {
                navigate(urls.register.path)
              }
            }
          }

          // ошибка
        } else {
          setFormError(error(data))
        }
        setBtnActive(true)
      })
  }

  const timerExpired = () => {
    setResendCode(true)
  }

  return (
    <>
      <h1 className='form-card__title-24'>Введите код из СМС</h1>
      <div className='mt-4 mb-8'>
        {resendCode ? <button onClick={() => navigate(urls.login.path)} className='cabinet-btn mb-4'>Отправить код снова</button>
          : <p className='form-card__description'>Запросить повторно через <Timer seconds={30} expired={timerExpired} /></p>
        }
      </div>
      {/* <SmartCaptcha
        sitekey={process.env.REACT_APP_CAPTCHA_CLIENT_KEY}
        onTokenExpired={() => setToken('')}
        onSuccess={setToken}
        language={'ru'} /> */}
      {formError && <p className='form__error'>{formError}</p>}

      {fields.map(item => (
        <div>
          <FormItem field={item} key={item.id} mask={item.mask || false} value={data[item.name]} setValue={setValue} setError={setError} />
        </div>
      ))}
      <span className={`form-btn _block mt-16 ${btnActive ? '' : '_disabled'}`} onClick={send_login}>Отправить</span>
    </>
  )
}

export default LoginCodeForm