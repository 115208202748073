import { useEffect, useState } from "react";

const Timer = ({ seconds, expired }) => {
    const [timeLeft, setTimeLeft] = useState(seconds)

    useEffect(() => {
        if (timeLeft <= 0) return expired();
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => Math.max(prevTime - 0.01, 0));
        }, 10);

        return () => clearInterval(intervalId);
    }, [timeLeft])

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);

        return `${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
    };

    return <>{formatTime(timeLeft)}</>
}

export default Timer