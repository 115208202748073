const organisationCheck = (data) => {
    if (data.organisation.length === 1)
        return data.organisation[0].name
    else if (data.organisation.length === 2)
        return data.organisation[0].name + ', ' + data.organisation[1].name
    return ''
}

const postCheck = (data) => {
    if (data.post_bik && !data.post_fond)
        return data.post_bik
    else if (!data.post_bik && data.post_fond)
        return data.post_fond
    else if (data.post_bik && data.post_fond)
        return data.post_bik + ', ' + data.post_fond
    return ''
}

const getDomain = () => ('https://collback.bik31.ru') //'http://192.168.32.50:8001' '8011' 'https://collback.bik31.ru'

function setUserData(data, addUserData) {
    localStorage.setItem("user_id", data.id)
    addUserData(['user_id', data.id])

    const newGroups = []
    const permissions = []
    for (const group of data.groups) {
        try {
            for (const permission of group.permissions) {
                permissions.push(permission.codename)
            }
        } catch (error) {
            console.log('permissions error', group, error)
        }
        newGroups.push(group.name)
    }

    localStorage.setItem("groups", JSON.stringify(newGroups))
    addUserData(['groups', JSON.stringify(newGroups)])
    localStorage.setItem("permissions", JSON.stringify(permissions))
    addUserData(['permissions', JSON.stringify(permissions)])

    const post = postCheck(data)
    localStorage.setItem("post", post)
    addUserData(['post', post])

    const organisation = organisationCheck(data)
    localStorage.setItem("organisation", organisation)
    addUserData(['organisation', organisation])

    const user_name = data.last_name ? data.last_name + ' ' + data.first_name[0] + '.' + data.patronymic[0] + '.' : data.username
    localStorage.setItem("user_name", user_name)
    addUserData(['user_name', user_name])
}

const getFileName = (name) => {
    const decodedUrl = decodeURIComponent(name)
        , arr_path = decodedUrl.split('/')
        , file_name = arr_path[arr_path.length - 1]
        , file_name_arr = file_name.split('_')
        , new_name = file_name.replace('_' + file_name_arr[file_name_arr.length - 1].split('.')[0], "")
    return new_name
}

function hasDoubleNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            for (let j = 0; j < arr[i].length; j++) {
                if (Array.isArray(arr[i][j])) {
                    return true; // Если найден двойной вложенный массив, вернуть true
                }
            }
        }
    }
    return false; // Если двойной вложенный массив не найден, вернуть false
}
//проверка на массив массивов
function hasNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            return true; // Если элемент массив, вернуть true
        }
    }
    return false; // Если ни один элемент не является массивом, вернуть false
}

function fieldToArray(fields) {
    if (!fields) {
        return [];
    }

    const fieldEntries = Object.entries(fields);

    if (fieldEntries.length === 0) {
        return [];
    }

    const resultArray = fieldEntries.map(([key, value]) => ({ key, value }));
    return resultArray;
}

const getMaxDepth = arr =>
    Array.isArray(arr)
        ? 1 + Math.max(0, ...arr.map(getMaxDepth))
        : 0;


export { getMaxDepth, fieldToArray, hasDoubleNestedArray, hasNestedArray, getDomain, setUserData, getFileName }