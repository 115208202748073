import { createSlice } from '@reduxjs/toolkit'

const userData = {
    refresh: (localStorage.getItem("refresh") ? localStorage.getItem("refresh") : ''),
    token: (localStorage.getItem("token") ? localStorage.getItem("token") : ''),
    user_id: (localStorage.getItem("user_id") ? JSON.parse(localStorage.getItem("user_id")) : ''),
    user: (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ''),
    user_login: (localStorage.getItem("user_login") ? JSON.parse(localStorage.getItem("user_login")) : ''),
    contragent: (localStorage.getItem("contragent") ? JSON.parse(localStorage.getItem("contragent")) : ''),
}

export const userDataSlice = createSlice({
    name: 'user_data',
    initialState: {
        userData
    },
    reducers: {
        login(state, action) {
            state.userData[action.payload[0]] = action.payload[1]
            localStorage.setItem(action.payload[0], action.payload[1])
        },
        setUserDataItem(state, action) {
            state.userData[action.payload[0]] = action.payload[1]
            localStorage.setItem(action.payload[0], JSON.stringify(action.payload[1]))
        },
        cleanUserData(state) {
            for (const key of Object.keys(state.userData)) {
                state.userData[key] = ''
                localStorage.removeItem(key)
            }
        }
    }
})

export const { setUserDataItem, cleanUserData, login } = userDataSlice.actions
export default userDataSlice.reducer