import React, { useEffect, useMemo } from 'react'

import './LandMapPage.scss'
import SimplePage from '../../components/SimplePage/SimplePage'
import MapPage from '../../components/MapPage/MapPage'
import { YMaps } from '@pbe/react-yandex-maps'
import { useLocation } from 'react-router-dom'
import urls from '../../urls'


const LandMapPage = () => {
    const loction = useLocation()
    const isCommertial = useMemo(() => {
        const params = new URLSearchParams(loction.search)
        const landplotsType = params.get('type')
        if (landplotsType) {
            if (landplotsType === 'COMMERCIAL_PLOTS')
                return true
            if (landplotsType === 'RESIDENTIAL_PLOTS')
                return false
        }
        return false
    }, [loction])

    return (
        <SimplePage>
            {isCommertial && <>
                <h2 className='map-page-title'>АО «БИК» предлагает земельные участки коммерческого назначения для реализации будущих проектов</h2>
                <p className='stupid-map-info__top-text'>Почему стоит приобрести земельный участок для бизнеса в АО «БИК»?</p>
                <div className='stupid-map-info'>
                    <div>
                        <p className='stupid-map-info__text _bold'>Подбор земельного участка</p>
                        <p className='stupid-map-info__text'>Персональный менеджер подберет земельный участок для любых целей вашего бизнеса</p>
                    </div>
                    <div>
                        <p className='stupid-map-info__text _bold'>Снижение стоимости</p>
                        <p className='stupid-map-info__text'>Стоимость земельного участка может быть снижена при предоставлении проектной документации на планируемый коммерческий объект</p>
                    </div>
                    <div>
                        <p className='stupid-map-info__text _bold'>Возможность преобразования</p>
                        <p className='stupid-map-info__text'>Земельный участок может быть преобразован в соответствии с желаемыми параметрами (площадь, вид разрешенного использования, категория и т.п.).</p>
                    </div>
                    <div>
                        <p className='stupid-map-info__text _bold'>Вынос в натуру границ земельного участка</p>
                        <p className='stupid-map-info__text'>Специалисты АО «БИК» осуществляют показ земельного участка на местности с определением его границ до сделки купли-продажи</p>
                    </div>
                    <div>
                        <p className='stupid-map-info__text _bold'>Лояльные условия оплаты</p>
                        <p className='stupid-map-info__text'>Возможность внесения платежей в рассрочку до 12 месяцев</p>
                    </div>
                </div>
                <p className='stupid-map-info__bottom-text'>Не упустите возможность приобрести идеальный земельный участок для бизнеса! Свяжитесь с нами сегодня и узнайте больше о наших услугах </p>
            </>}

            <h1 className='map-page-title2'>{isCommertial ? urls.buy_land_com.title : urls.buy_land.title}</h1>
            <MapPage />
            <div className='map-info'>
                <p className='map-info__text _bold'>На данный момент система работает в режиме тестирования: могут возникать сбои в бронировании
                    и существует возможность бронирования одного и того же участка несколько раз разными клиентам.
                    Мы оставляем за собой право закреплять бронирование за первым человеком, оставившим заявку.
                    Приносим извинения за неудобства.</p>

                <p className='map-info__text'>Предварительное бронирование не является публичной офертой, носит исключительно ознакомительный
                    характер с предлагаемыми земельными участками для продажи. Предварительное бронирование
                    работает только на один забронированный участок, последующие бронирования участков не принимаются.
                    Цены на земельные участки не являются окончательными и могут быть изменены продавцом
                    в одностороннем порядке до заключения договора купли-продажи.</p>
            </div>
        </SimplePage >
    )
}

export default LandMapPage