import React, { useEffect } from 'react'
import { cleanUserData, login, setUserDataItem } from '../../store/userDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import urls from '../../urls'
import CabinetRequest from '../../api/cabinet/Cabinet'

const Refresh = () => {
    const dispatch = useDispatch()
        , navigate = useNavigate()
        , userData = useSelector(state => state.user_data.userData)
        , addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
        , userLogin = (name, value) => dispatch(login([name, value]))
        , cabinet = new CabinetRequest()

    useEffect(() => {
        cabinet.refresh({ refresh: userData.refresh })
            .then(response => {
                if (response.success && response.data.refresh && response.data.access) {
                    userLogin('refresh', response.data.refresh)
                    userLogin('token', response.data.access)
                    // setTimeout(() => {
                        window.location.pathname = urls.cabinet_page.path
                    // }, 100)
                    // navigate(urls.cabinet_page.path)
                } else {
                    dispatch(cleanUserData())
                    // setTimeout(() => {
                        window.location.pathname = urls.login.path
                    // }, 100)
                    // navigate(urls.login.path)
                }
            })
    }, [])

    return (
        <div className='container'>
            <span onClick={() => {}}>Перейти в кабинет</span>
            
            </div>
    )
}

export default Refresh