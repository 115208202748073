import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { SmartCaptcha } from '@yandex/smart-captcha';

import FormItem from '../../UI/fields/FormItem'
import { onPhoneInput } from '../../../functions/mask'
import { setUserDataItem } from '../../../store/userDataSlice'
import urls from '../../../urls';
import CabinetRequest from '../../../api/cabinet/Cabinet';


const LoginForm = () => {

  const cabinet = new CabinetRequest()

  const dispatch = useDispatch()
  const addUserData = (name, value) => dispatch(setUserDataItem([name, value]))
  const [btnActive, setBtnActive] = useState(false)
  const [formError, setFormError] = useState(false)
  const navigate = useNavigate();
  const userData = useSelector(state => state.user_data.userData)
  const [token, setToken] = useState(''); //capcha
  const [fields, setFields] = useState([
    {
      id: 'phone',
      name: 'phone',
      type: 'text',
      label: 'Номер телефона',
      mask: onPhoneInput
    },
  ])
  const [data, setData] = useState({
    phone: userData.user_login ? userData.user_login : '',
    captcha_token: '',
  })

  useEffect(() => {
    if (token) {
      data.captcha_token = token
      setData({ ...data })
    }

    if (data.phone && token) {
      setBtnActive(true)
    }
  }, [token])

  const setValue = (name, value) => {
    if (value) {
      data[name] = value
    } else {
      delete data[name]
    }
    setData({ ...data })
    if (data.phone && token) {
      setBtnActive(true)
    } else {
      setBtnActive(false)
    }
  }

  const setError = (name, value) => {
    const index = fields.findIndex(field => field.name === name)
    fields[index].error = value
    setFields(fields)
  }

  const send_code = () => {
    addUserData('user_login', data.phone)
    setBtnActive(false)
    if (data.phone && data.captcha_token) {
      cabinet.sendCode(JSON.stringify(data))
        .then(data => {
          if (data.success) {
            navigate(urls.login_code.path)
          } else {
            setFormError('Ошибка ' + data.message)
          }
          setBtnActive(true)
        })
    }
  }

  return (
    <>
      <h1 className='form-card__title'>Вход</h1>
      <p className='form-card__description'>для действующих и будущих клиентов БИК</p>
      {formError && <p className='form__error'>{formError}</p>}
      {fields.map(item => (
        <FormItem field={item} key={item.id} mask={item.mask || false} value={data[item.name]} setValue={setValue} setError={setError} />
      ))}
      <SmartCaptcha
        sitekey={process.env.REACT_APP_CAPTCHA_CLIENT_KEY}
        onTokenExpired={(e) => {console.log('onTokenExpired e', e); setToken('')}}
        onSuccess={setToken}
        language={'ru'} />
      <p className='form-help-text'>Нажимая на кнопку "Войти" я даю согласие на обработку персональных данных в соответствии с  <a href={urls.soglashenie.path} className='' target='_blank'>Пользовательским соглашением и политикой обработки персональных данных</a></p>
      <span className={`form-btn _block mt-16 ${btnActive ? '' : '_disabled'}`} onClick={send_code}>Войти</span>
      {/* <span className='second-form-btn _block mt-8'>Регистрация</span> */}
    </>
  )
}

export default LoginForm