import React, { useState, useEffect, useRef } from 'react';
import { Map, Polygon, Polyline, YMaps } from '@pbe/react-yandex-maps';

import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedLandploat } from '../../../store/mapDataSlice';
import { hasDoubleNestedArray, hasNestedArray } from '../../../functions/functions';
import urls from '../../../urls';
import Loader from '../../UI/Loader/Loader';


const MapComponents = ({ styleContainer, mapStyle = '', activeLandploat }) => {
    const mapData = useSelector(state => state.map_data.mapData)
    const dispatch = useDispatch()
    const setLandPloat = (obj) => dispatch(setSelectedLandploat(obj))
    //const setDistrict = id => dispatch(setActiveDistrict(id))
    //const setMunicipality = id => dispatch(setActiveMunicipality(id))
    const landplots = mapData.landplotsList?.length > 0 ? mapData.landplotsList : []
    const [placemarks, setPlacemarks] = useState([]);
    //const [center, setCenter] = useState({ 0: NaN, 1: NaN })
    const navigate = useNavigate();
    const coordinats = mapData.coordinatesItem;
    const [loading, setLoading] = useState(true)

    const mapRef = useRef(null);
    const [ymaps, setYmaps] = useState('');

    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)



    const getFlatCoords = (coordinates, arr) => {
        setLoading(true)
        if (coordinates.length > 0) {
            if (Array.isArray(coordinates[0])) {
                coordinates.map(coords => {
                    getFlatCoords(coords, arr)
                })
            }
        }
        //arr.push(coordinates)
        setPlacemarks(prev => [...prev, coordinates])
        return
    }

    useEffect(() => {
        getFlatCoords(coordinats, [])
        setLoading(false)
    }, [...coordinats]);

    const setCenter = (ref, coords = false) => {
        // First of all check if ymaps is available

        if (ymaps) {
            // get map ref, cause we need map size
            const map = ref.getMap();
            const bounds = ref.geometry.getBounds()
            const size = map.container.getSize()
            //const zoom = map.getZoom()
            // console.log('bounds', bounds);
            // console.log('size', size);
            // console.log('zoom', map.getZoom());
            // Using https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/util.bounds-docpage/#method_detail__getCenterAndZoom
            const result = ymaps.util.bounds.getCenterAndZoom(
                //ymaps.util.bounds.fromPoints(coordinats),
                bounds,
                size
            );

            //console.log('result', result)

            // // Setting the optimal center and zoom level of the map.
            if (result.center && result.zoom) {
               // console.log('zoom', result.zoom);
                map.setCenter(result.center, result.zoom - 1);
            }
        }
    };

    const centeredFromAnyIncludedArray = (coords) => {
        return <Polyline
            geometry={[...coords, coords[0]]}
            options={{ strokeColor: '#00000000', strokeWidth: 3, strokeStyle: "solid" }}
            instanceRef={ref => ref && setCenter(ref, 'coordinats')}
        />
    }

    const drawPolygon = (coordinates, index, onClick) => {
        //if (hasDoubleNestedArray(coordinates)) {
        if (hasDoubleNestedArray(coordinates)) {
            coordinates.map((coords, index) => {
                return drawPolygon(coords, index)
            })
        } else if (hasNestedArray(coordinates)) {
            //console.log('hasNestedArray', coordinates);
            return <Polygon
                key={index}
                geometry={[coordinates]}
                options={{ strokeColor: '#0088CC', strokeWidth: 2, strokeStyle: "solid", fillColor: "#0088CC10" }}
                onClick={onClick}
            />

        }
        //console.log('!hasNestedArray', coordinates);
        return <Polygon
            key={index}
            geometry={coordinates}
            options={{ strokeColor: '#0088CC', strokeWidth: 2, strokeStyle: "solid", fillColor: "#0088CC10" }}
            onClick={onClick}
        />

    }

    const dataCoordCenter = activeLandploat != undefined ? (activeLandploat.coordinates.length > 1) ? activeLandploat.coordinates : activeLandploat.coordinates[0] : coordinats;
    //('dataCoordCenter', dataCoordCenter);
    // Вычисляем средние значения широты и долготы
    let sumLat = 0;
    let sumLng = 0;

    console.log('dataCoordCenter', dataCoordCenter)

    if (hasDoubleNestedArray(coordinats) && dataCoordCenter.every(coordsData => coordsData.length > 0)) {
        for (const coords of dataCoordCenter) {
            if (coords && coords[0]) {
                if (coords[0][0] !== undefined && coords[0][1] !== undefined) {
                    sumLat += coords[0][0]; // Широта (latitude)
                    sumLng += coords[0][1]; // Долгота (longitude)
                } else {
                    sumLat += coords[0];
                    sumLng += coords[1];
                }
            } else {
                console.error('Invalid coordinates:', coords);
            }
        }

    } else if (dataCoordCenter.every(coordsData => coordsData.length > 0)) {
        for (const coords of dataCoordCenter) {
            if (coords && coords[0] && coords[0] !== undefined && coords[1] !== undefined) {
                sumLat += coords[0]; // Широта (latitude)
                sumLng += coords[1]; // Долгота (longitude)
            } else {
                console.error('Invalid coordinates:', coords);
            }
        }

    } else {
        console.error('Some elements in dataCoordCenter are empty.');
    }

    const center =
    {
        0: sumLat / dataCoordCenter?.length,
        1: sumLng / dataCoordCenter?.length
    };

    return (
        <div className={`mapContainer ${styleContainer}`}>
            {loading ? <Loader /> :
                <YMaps query={{
                    apikey: 'a2718abd-328d-4bd5-a0e1-f246345a30d4',
                    lang: "ru_RU",
                    load: "util.bounds"
                }}>
                    {!mapData.error ? coordinats.length > 0 ?
                        <Map
                            ref={mapRef}
                            defaultState={{
                                //center: [50.593776, 36.585727],
                                center:
                                    (!isNaN(center[0]) && !isNaN(center[1])) ? center : [50.593776, 36.585727],
                                zoom: mapData.isActiveDistrict ? 14 : 10
                            }}
                            onLoad={ymaps => setYmaps(ymaps)}
                            mode={'debug'}
                            //modules={["geoObject.addon.editor"]}
                            className={`mapStyle ${mapStyle}`}>

                            {/* {activeLandploat?.coordinates.length > 0 ?
                            <Polyline
                                geometry={activeLandploat.coordinates}
                                options={{ strokeColor: '#000000', strokeWidth: 3, strokeStyle: "solid" }}
                                instanceRef={ref => ref && setCenter(ref, 'activeLandploat.coordinates')}
                            /> : placemarks.length > 0 ?
                                <Polyline
                                    geometry={[...placemarks, ...placemarks[0]]}
                                    options={{ strokeColor: '#000000', strokeWidth: 3, strokeStyle: "solid" }}
                                    instanceRef={ref => ref && setCenter(ref, 'coordinats')}
                                />
                                : <></>
                        } */}

                            {activeLandploat?.coordinates.length > 1 ?
                                <Polyline
                                    geometry={activeLandploat.coordinates}
                                    options={{ strokeColor: '#000000', strokeWidth: 3, strokeStyle: "solid" }}
                                    instanceRef={ref => ref && setCenter(ref, 'activeLandploat.coordinates')}
                                />
                                : activeLandploat?.coordinates[0].length > 0 ?
                                    <Polyline
                                        geometry={activeLandploat.coordinates[0]}
                                        options={{ strokeColor: '#000000', strokeWidth: 3, strokeStyle: "solid" }}
                                        instanceRef={ref => ref && setCenter(ref, 'activeLandploat.coordinates')}
                                    /> : placemarks.length > 0 ?
                                        // <Polyline
                                        //             geometry={[...placemarks, placemarks[0]]}
                                        //             options={{ strokeColor: '#000000', strokeWidth: 3, strokeStyle: "solid" }}
                                        //             instanceRef={ref => ref && setCenter(ref, 'coordinats')}
                                        //         />
                                        centeredFromAnyIncludedArray(placemarks)
                                        : coordinats.length > 0 &&
                                        centeredFromAnyIncludedArray(coordinats)
                            }

                            {mapData.isActiveDistrict ?
                                hasDoubleNestedArray(coordinats) ?
                                    coordinats.map((coords, index) => (
                                        <Polyline geometry={[...coords, coords[0]]} options={{ strokeColor: '#0088CC', strokeWidth: 3 }} />
                                    ))
                                    :
                                    <Polyline geometry={[...coordinats, coordinats[0]]} options={{ strokeColor: '#0088CC', strokeWidth: 3 }} />
                                : <></>
                            }

                            {mapData.isActiveMunicipality ?
                                <></> :
                                mapData.municipalitiesList.map(item => {
                                    return drawPolygon(item.coordinates, 0, () => window.location.search = `?type=${params.get('type')}&municipality=${item.id}`)
                                })
                            }

                            {mapData.isActiveDistrict ?
                                <></> :
                                mapData.microdistrictList.map(item => {
                                    return drawPolygon(item.coordinates, 0, () => window.location.search = `?type=${params.get('type')}&municipality=${mapData.isActiveMunicipality}&district=${item.id}`)
                                })
                            }

                            {landplots.length > 0 && landplots.map((landplot, index) => {
                                if (landplot?.coordinates.length > 0) {
                                    return <Polygon
                                        key={index}
                                        geometry={landplot.coordinates}
                                        options={{
                                            fillColor: 'rgba(3, 150, 255, 0.69)',
                                            strokeColor: '#0088CC',
                                            opacity: landplot?.cadastral === activeLandploat?.cadastral || landplot?.cadastral === mapData.selectedLandploat?.cadastral ? 1 : 0.3,
                                            strokeWidth: 1,
                                            strokeStyle: "solid",
                                        }}

                                        onClick={() => { setLandPloat(landplot); navigate(urls.buy_land_form.path + '?' + params.toString() + '&landplot=' + landplot.id) }} >
                                        <div style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            background: 'white',
                                            padding: '2px 4px',
                                            borderRadius: '4px',
                                            border: '1px solid #000',
                                            fontSize: '12px',
                                        }}>Текст на полигоне: {landplot.id}
                                        </div>
                                    </Polygon>
                                }
                            })}
                        </Map>
                        : <p className='map-error' >Пока нет участков для бронирования. Следите за обновлениями на нашем <span onClick={() => window.location = 'https://t.me/ao_bik31'} className='cabinet-btn mx-8'>телеграм-канале</span>
                        </p>
                        : <p className='map-error' >Возникла непредвиденная ошибка. Попробуйте
                            <span onClick={() => window.location.reload()} className='cabinet-btn mx-8'>перезагрузить страницу</span>
                            или зайти позже.
                        </p>}
                </YMaps>
            }
        </div>
    );
};

export default MapComponents;
