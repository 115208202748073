import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { useSelector } from "react-redux"
import LoginPage from "./pages/LoginPage/LoginPage";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import CabinetPage from "./pages/CabinetPage/CabinetPage";
import BuyLandPage from "./pages/BuyLandPage/BuyLandPage";
import BuyLandMarketPage from "./pages/BuyLandMarketPage/BuyLandMarketPage";
import FondIZHSPage from "./pages/FondIZHSPage/FondIZHSPage";
import BIKInvestPage from "./pages/BIKInvestPage/BIKInvestPage"

import urls from "./urls"
import BuildSelfcontrolForm from "./components/forms/BuildSelfcontrolForm/BuildSelfcontrolForm";
import DataForm from "./components/forms/DataForm/DataForm";
import LoginCodePage from "./pages/LoginCodePage/LoginCodePage";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import LandMapPage from "./pages/LandMapPage/LandMapPage";
import EditUserData from "./pages/EditUserData/EditUserData";
import Refresh from "./pages/Refresh/Refresh";
import RedirectPage from "./pages/RedirectPage";


function hasJWT() {
  if (localStorage.getItem("token")) {
    return true
  }
  return false
}

const PrivateRoute = () => {
  const userData = useSelector(state => state.user_data.userData)
  const isLoggedIn = userData.token && true // replace with your authentication logic
  const location = useLocation()

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={urls.login.path} replace state={{ from: location }} />
  );
};
const PublicRoute = () => {
  const userData = useSelector(state => state.user_data.userData)
  const isLoggedIn = userData.token && false // replace with your authentication logic
  
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={urls.cabinet_page.path} replace />
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={urls.cabinet_page.path} element={<PrivateRoute />} >
        <Route index element={<CabinetPage />} />
        <Route path={urls.market_form.path} element={<BuyLandMarketPage title={urls.market_form.title} />} />
        <Route path={urls.fond_izhs_form.path} element={<FondIZHSPage title={urls.fond_izhs_form.title} />} />
        <Route path={urls.bik_invest_form.path} element={<BIKInvestPage title={urls.bik_invest_form.title} />} />
        <Route path={urls.user_edit.path} element={<EditUserData title={urls.user_edit.title} />} />

        <Route path={urls.user_data_2.path} element={<DataForm title={urls.user_data_2.title} />} />


        {/* <Route path={urls.login.path} element={<Navigate to={urls.cabinet_page.path} replace />} />
        <Route path={urls.login_code.path} element={<Navigate to={urls.cabinet_page.path} replace />} />
        <Route path={urls.register.path} element={<Navigate to={urls.cabinet_page.path} replace />} /> */}

      </Route>

      {/* <Route path={urls.cabinet_page.path} element={<PublicRoute />} >
        <Route path={urls.login.path} element={<LoginPage />} />
        <Route path={urls.login_code.path} element={<LoginCodePage />} />
        <Route path={urls.register.path} element={<RegistrationPage />} />
      </Route> */}

      {hasJWT() ? (
        <>
          <Route path={urls.login.path} element={<Navigate to={urls.cabinet_page.path} replace />} />
          <Route path={urls.login_code.path} element={<Navigate to={urls.cabinet_page.path} replace />} />
          <Route path={urls.register.path} element={<Navigate to={urls.cabinet_page.path} replace />} />
        </>
      ) : (
        <>
          <Route path={urls.login.path} element={<LoginPage />} />
          <Route path={urls.login_code.path} element={<LoginCodePage />} />
          <Route path={urls.register.path} element={<RegistrationPage />} />
        </>
      )}

      {/* url изменился 26.02.2024 */}
      <Route path={'/map-land'} element={<RedirectPage to={urls.buy_land.path} />} /> 

      <Route path={urls.logout.path} element={<LogoutPage />} />
      <Route path={urls.refresh.path} element={<Refresh/>} />

      {/* GEO ИЖС */}
      <Route path={urls.buy_land.path} element={<LandMapPage title={urls.buy_land.title} />} />
      <Route path={urls.buy_land_com.path} element={<LandMapPage title={urls.buy_land_com.title} />} />
      <Route path={urls.buy_land_form.path} element={<BuyLandPage title={urls.buy_land_form.title} />} />

    </>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
